import React, { useCallback, useEffect, useState } from 'react';
import Navigation from '../componetns/Navigation';
import { TbPokeball } from 'react-icons/tb';
import { Header } from '../componetns/Header';
import Request from '../utils/Request';
import { useNavigate } from 'react-router-dom';
import { Button, TextField } from '@mui/material';
import { ImSpinner2 } from 'react-icons/im';
import { firstCapitalize } from '../utils/utils';

const textColor = 'text-[#CE5A67]';
const buttonStype = `!bg-[#CE5A67] hover:!bg-[#E78895]`;
const shadowColor = `shadown-[#CE5A67]`;

function Login() {
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [rePassword, setRePassword] = useState('');
	const [signUpToggen, setSignUpToggen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState({ username: '', password: '', other: '' });

	const navigate = useNavigate();
	useEffect(() => {
		if (Request.isTokenValid()) {
			navigate('/home', { replace: true });
		}
	}, []);

	const escFunction = useCallback((event) => {
		if (event.key === 'Enter') {
			handleButton(event, signUpToggen ? 2 : 1);
		}
	}, []);

	useEffect(() => {
		document.addEventListener('keydown', escFunction, false);

		return () => {
			document.removeEventListener('keydown', escFunction, false);
		};
	}, [escFunction]);

	function onUsername(event) {
		setUsername(event.target.value);
		if (
			signUpToggen &&
			event.target.value.length < 5 &&
			event.target.value.length > 1
		) {
			setError((prev) => {
				return {
					...prev,
					...{ username: 'Username : Minimum 5 char is required!', other: '' },
				};
			});
		} else {
			setError((prev) => {
				return { ...prev, ...{ username: '', other: '' } };
			});
		}
	}

	function onPassword(event) {
		setPassword(event.target.value);
		if (
			signUpToggen &&
			event.target.value.length < 5 &&
			event.target.value.length > 1
		) {
			setError((prev) => {
				return {
					...prev,
					...{ password: 'Password : Minimum 5 char is required!', other: '' },
				};
			});
		} else {
			setError((prev) => {
				return { ...prev, ...{ password: '', other: '' } };
			});
		}
	}

	function onRePassword(event) {
		setRePassword(event.target.value);
	}

	async function handleButton(event, id) {
		event.preventDefault();
		if (loading) {
			return;
		}
		if (!error.username && !error.password) {
			console.log('asdasda', id);
			if (id == 1) {
				setLoading(true);
				const success = await Request.login(username, password);
				if (success) {
					navigate('/home', { replace: true });
				} else {
					setError((prev) => {
						return { ...prev, ...{ other: 'Invalid username or password' } };
					});
				}
			} else {
				//signuop
				if (!signUpToggen) {
					setSignUpToggen(true);
				} else {
					if (password !== rePassword) {
						setError((prev) => {
							return { ...prev, ...{ other: 'Passwords did not match' } };
						});
					} else {
						setLoading(true);
						const success = await Request.signup(username, password);
						if (success.valid) {
							navigate('/home', { replace: true });
						} else {
							console.log(success);
							setError((prev) => {
								return {
									...prev,
									...{
										other: success.data?.message?.reduce(
											(val, prev) => prev + ',  ' + val
										),
									},
								};
							});
						}
					}
				}
			}
			setLoading(false);
		}
	}

	return (
		<div>
			<Header />
			<div className="h-screen flex flex-col items-center gap-5 justify-start">
				<img src="/images/others/oh_oh_bg.png" className="w-[25%]" />
				<h1 className={`text-center text-3xl font-bold ${textColor}`}>
					Pokemon-based Browser RPG
				</h1>

				<form action="" className="flex flex-col items-center gap-3">
					<input
						className={`${
							error.username ? 'border-2 border-red-500' : ''
						} text-[#1F1717] w-56 h-9 rounded-sm pl-3 py-1 shadow-sm ${shadowColor}`}
						name="username"
						placeholder="Username"
						value={username}
						type="text"
						onChange={onUsername}
					/>
					<input
						className={`${
							error.password ? 'border-2 border-red-500' : ''
						} text-[#1F1717] w-56 h-9 rounded-sm pl-3 py-1 shadow-sm ${shadowColor}`}
						name="password"
						placeholder="Password"
						value={password}
						type="password"
						onChange={onPassword}
					/>
					<input
						className={`${signUpToggen ? '' : 'hidden'} ${
							error.password ? 'border-2 border-red-500' : ''
						} text-[#1F1717] w-56 h-9 rounded-sm pl-3 py-1 shadow-sm ${shadowColor}`}
						name="repassword"
						value={rePassword}
						placeholder="Re-enter Password"
						type="password"
						onChange={onRePassword}
					/>
					<div className="flex gap-4">
						<Button
							variant="contained"
							onClick={(event) => {
								handleButton(event, 1);
							}}
							size="small"
							className={`${signUpToggen ? '!hidden ' : ''}${buttonStype}`}
						>
							Login
							{loading ? (
								<ImSpinner2 className="animate-spin inline ml-1" />
							) : (
								''
							)}
						</Button>
						<Button
							variant="contained"
							size="small"
							onClick={(event) => {
								handleButton(event, 2);
							}}
							className={`${signUpToggen ? '!hidden' : ''} ${buttonStype}`}
						>
							Signup
							{loading ? (
								<ImSpinner2 className="animate-spin inline ml-1" />
							) : (
								''
							)}
						</Button>
						<Button
							variant="contained"
							onClick={(event) => {
								handleButton(event, 2);
							}}
							size="small"
							color="success"
							className={`${signUpToggen ? '' : '!hidden'}`}
						>
							Continue
							{loading ? (
								<ImSpinner2 className="animate-spin inline ml-1" />
							) : (
								''
							)}
						</Button>
						<Button
							variant="contained"
							onClick={(event) => {
								event.preventDefault();
								setRePassword('');
								setSignUpToggen(false);
							}}
							color="error"
							size="small"
							className={`${signUpToggen ? '' : '!hidden'}`}
						>
							Cancel
						</Button>
					</div>
					<p
						className={`${
							error.username ? 'visible' : 'invisible'
						} bg-red-200 text-red-600 rounded-md px-4`}
					>
						{error.username}
					</p>
					<p
						className={`${
							error.password ? 'visible' : 'invisible'
						} text-red-600 bg-red-200 rounded-md px-4`}
					>
						{error.password}
					</p>
					<p
						className={`${
							error.other ? 'visible' : 'invisible'
						} text-red-600 bg-red-200 rounded-md px-4 mx-5`}
					>
						{firstCapitalize(error.other)}
					</p>
				</form>
			</div>
		</div>
	);
}

export default Login;
