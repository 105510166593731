export const DIRECTIONS = {
	NORTH_EAST: 'ne',
	NORTH: 'n',
	NORTH_WEST: 'nw',
	EAST: 'e',
	WEST: 'w',
	SOUTH_EAST: 'se',
	SOUTH: 's',
	SOUTH_WEST: 'sw',
};

export const POKEMON_KIND = {
	NORMAL: 1,
	DARK: 2,
	SHINY: 3,
};

export const BATTLE_TYPE = {
	WILD_BATTLE: 'wb',
	GYM: 'gym',
	USER: 'user',
	TRAINING: 'training',
};

export const ALL_POKEMON_TYPES = [
	'normal',
	'fire',
	'water',
	'electric',
	'grass',
	'ice',
	'fighting',
	'poison',
	'ground',
	'flying',
	'psychic',
	'bug',
	'rock',
	'ghost',
	'dragon',
	'dark',
	'steel',
	'fairy',
];

export const NAVIGATION = {
	MAPS: 'Maps',
	LOGIN: 'Login',
	HOME: 'Home',
	SIGNUP: 'Signup',
	FAQ: 'F.A.Q',
	ABOUT: 'About',
	YOUR_PROFILE: 'Your profile',
	POKEMART: 'Pokemart',
	YOUR_TEAM: 'Your team',
	YOUR_POKEMON: 'Your Pokemon',
	POKEDEX: 'Pokedex',
	ATTACKDEX: 'AttackDex',
	TYPE_CHART: 'Type Chart',
	GYM_BATTLE: 'Gym Battle',
	USER_BATTLE: 'User Battle',
	GOLD_BATTLES: 'Gold Battle',
	EXP_BATTLES: 'Exp battle',
	YOUR_TRADERS: 'Your Traders',
	YOUR_OFFERS: 'Your offers',
	LOGOUT: 'Logout',
	MAP_SELECTION: 'Map Selection',
};

export const PATH_MAPPING = {
	[NAVIGATION.MAPS]: '/mapSelection',
	[NAVIGATION.LOGIN]: '/login',
	[NAVIGATION.HOME]: '/home',
	[NAVIGATION.SIGNUP]: '/signup',
	[NAVIGATION.FAQ]: '/faq',
	[NAVIGATION.ABOUT]: '/about',
	[NAVIGATION.YOUR_PROFILE]: '/profile',
	[NAVIGATION.POKEMART]: '/pokemart',
	[NAVIGATION.YOUR_TEAM]: '/team',
	[NAVIGATION.YOUR_POKEMON]: '/pokemons',
	[NAVIGATION.POKEDEX]: '/pokedex',
	[NAVIGATION.ATTACKDEX]: '/attackdex',
	[NAVIGATION.TYPE_CHART]: '/typechart',
	[NAVIGATION.GYM_BATTLE]: '/gymbattle',
	[NAVIGATION.USER_BATTLE]: '/userbattle',
	[NAVIGATION.GOLD_BATTLES]: '/goldbattle',
	[NAVIGATION.EXP_BATTLES]: '/expbattle',
	[NAVIGATION.YOUR_TRADERS]: '/trades',
	[NAVIGATION.YOUR_OFFERS]: '/offers',
	[NAVIGATION.MAP_SELECTION]: '/maps',
};

export const REGIONS = {
	KANTO: 'kanto',
	JOHTO: 'johto',
	HOENN: 'hoenn',
	SINNOH: 'sinnoh',
	UNOVA: 'unova',
	KALOS: 'kalos',
	ALOLA: 'alola',
	GALAR: 'galar',
	PALDEA: 'paldea',
};

export const REGIOM_GYM_LEADERS = {
	kanto: [
		'brock',
		'misty',
		'lt. surge',
		'erika',
		'janine',
		'sabrina',
		'blaine',
		'giovanni',
		'lorelei',
		'bruno',
		'agatha',
		'lance',
		'blue',
	],
	johto: [
		'falkner',
		'bugsy',
		'whitney',
		'morty',
		'chuck',
		'jasmine',
		'pryce',
		'clair',
		'will',
		'koga',
		'bruno',
		'karen',
		'lance',
		'red',
	],
	hoenn: [
		'roxanne',
		'brawly',
		'wattson',
		'flannery',
		'norman',
		'winona',
		'tate and liza',
		'wallace',
		'juan',
		'sidney',
		'phoebe',
		'glacia',
		'drake',
		'steven',
		'wallace',
	],
	sinnoh: [
		'roark',
		'gardenia',
		'maylene',
		'wake',
		'fantina',
		'byron',
		'candice',
		'volkner',
		'aaron',
		'bertha',
		'flint',
		'lucian',
		'cynthia',
	],
	unova: [
		'cilan',
		'chili',
		'cress',
		'lenora',
		'cheren',
		'roxie',
		'burgh',
		'elesa',
		'clay',
		'skyla',
		'brycen',
		'drayden',
		'marlon',
		'shauntal',
		'marshal',
		'grimsley',
		'caitlin',
		'alder',
		'iris',
	],
	kalos: [
		'viola',
		'grant',
		'korrina',
		'ramos',
		'clemont',
		'valerie',
		'olympia',
		'wulfric',
		'malva',
		'siebold',
		'wikstrom',
		'drasna',
		'diantha',
	],
	alola: [
		'ilima',
		'hala',
		'lana',
		'kiawe',
		'mallow',
		'olivia',
		'sophocles',
		'acerola',
		'nanu',
		'kukui',
		'mina',
		'hapu',
		'molayne',
		'olivia',
		'acerola',
		'kahili',
		'hau',
	],
	galar: [
		'milo',
		'nessa',
		'kabu',
		'bea',
		'allister',
		'opal',
		'gordie',
		'melony',
		'piers',
		'raihan',
		'marnie',
		'hop',
		'bede',
		'leon',
	],
	paldea: [
		'katy',
		'brassius',
		'iono',
		'kofu',
		'larry',
		'ryme',
		'tulip',
		'grusha',
		'rika',
		'poppy',
		'larry',
		'hassel',
		'geeta',
	],
};

export const pokemonKind = ['', 'normal', 'dark', 'shiny'];
