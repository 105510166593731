import axios from 'axios';
import { useEffect, useState } from 'react';
import Request from '../utils/Request';
import { useLocation, useNavigate } from 'react-router-dom';
import { DIRECTIONS, pokemonKind } from '../constants';
import { Header } from '../componetns/Header';
import { firstCapitalize } from '../utils/utils';
import { getItem, setItem } from '../utils/storage.utils';
import { Button } from '@mui/material';
import { MdArrowBackIos } from 'react-icons/md';
import { css } from '../css_constants';
import { ImSpinner2 } from 'react-icons/im';

let moveBlock = false;
let catchBlock = false;
const textColor = 'text-[#FFF5E0]';
const directions = [
	DIRECTIONS.NORTH_EAST,
	DIRECTIONS.NORTH,
	DIRECTIONS.NORTH_WEST,
	DIRECTIONS.EAST,
	DIRECTIONS.EAST,
	DIRECTIONS.WEST,
	DIRECTIONS.SOUTH_EAST,
	DIRECTIONS.SOUTH,
	DIRECTIONS.SOUTH_WEST,
];

function Arrow(props) {
	return (
		<div
			onClick={() => {
				if (!props.disable) {
					props.onMove(directions[props.id]);
				}
			}}
			className={`${css.mapArrow} ${
				props.hideIcon || props.disable ? 'active:opacity-0' : ''
			} cursor-pointer `}
		>
			{props.hideIcon ? (
				''
			) : (
				<MdArrowBackIos
					color={props.disable ? 'grey' : css.redColor}
					className={`${props.rotation} `}
					size={props.size || 30}
				/>
			)}
		</div>
	);
}

export default function Map(props) {
	const { state } = useLocation();
	const { map } = state || {};
	const [mapData, setMapData] = useState();
	const [availablePos, setAvailablePos] = useState({});
	const [position, setPosition] = useState({ x: '287px', y: '39px' });
	const [pokemon, setPokemon] = useState(null as any);
	const [error, setError] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const navigate = useNavigate();
	const profile = getItem('profile');

	useEffect(() => {
		if (!map) {
			navigate('/mapSelection', { replace: true });
			return;
		}
		if (Request.isTokenValid()) {
			Request.mapRequest(map)
				.then((mapData: any) => {
					setMapData(mapData.data);
					setPosition(mapData.data.mapPos);
					setAvailablePos(mapData.data.availablePos);
				})
				.catch((err) => {
					setError(err.message);
				});
		} else {
			// navigate('/Login', { replace: true });
		}
	}, []);

	function onMove(movePath) {
		if (Request.isTokenValid()) {
			if (!moveBlock && availablePos[movePath]) {
				setIsLoading(true);
				moveBlock = true;
				Request.mapMove(movePath)
					.then((mapData) => {
						setPosition(mapData.data.mapPos);
						setAvailablePos(mapData.data.availablePos);
						setPokemon(mapData.data.pokemon);
					})
					.catch((err) => {
						setError('Failed to move! Please reload');
					})
					.finally(() => {
						setIsLoading(false);
						moveBlock = false;
					});
			}
		} else {
			navigate('/Login', { replace: true });
		}
	}
	function onCatch() {
		if (!catchBlock) {
			catchBlock = true;
			Request.startWildBattle()
				.then((mapData) => {
					if (mapData.data.battleDetails.page) {
						setItem('battle', mapData.data.battleDetails);
						navigate('/battle', {
							replace: true,
						});
					}
				})
				.catch(() => {})
				.finally(() => {
					catchBlock = false;
				});
		}
	}

	return (
		<div>
			<Header />
			{error ? (
				<div className="flex w-full h-full justify-center items-center">
					{error}
				</div>
			) : (
				<div className="grid grid-cols-3 z-0">
					<h1
						className={`col-span-3 place-self-center p-2 m-1 mt-5 ${css.LOGIN.titlebgColor} rounded-md`}
					>
						Map, realm
					</h1>
					<div className="col-span-3 place-self-center flex">
						<div
							className={`w-[496px] h-[496px] max-sm:w-[372px] max-sm:h-[372px] bg-contain place-self-end relative shadow-sm  border-2 ${css.borderRedColor} inline-block`}
							style={{
								backgroundImage: `url(/images/maps/${map}.png)`,
							}}
						>
							{pokemon ? (
								<div className="w-full h-full flex justify-center items-center bg-[#1f1717]/80 z-20">
									<div className="flex flex-col justify-center items-center text-center ">
										<img
											className="w-15 max-sm:w-13 h-auto"
											src={`/images/front/${
												pokemonKind[pokemon.kind]
											}/${pokemon.name.toLowerCase()}.png`}
											// src={`/${pokemon.name.toLowerCase()}.png`}
										/>
										<p
											className={`text-sm max-sm:text-xs font-bold ${css.textLightColor}`}
										>
											Wild{' '}
											{`${
												pokemonKind[pokemon.kind] == 'normal'
													? ''
													: firstCapitalize(pokemonKind[pokemon.kind])
											} ${firstCapitalize(pokemon.name)} appeared!`}
										</p>
										<p
											className={`text-sm max-sm:text-xs mb-1 font-semibold ${css.textLightColor}`}
										>
											Level : {pokemon.level}
										</p>
										<Button
											size="small"
											className="!bg-[#CE5A67] !text-[#FFF5E0]"
											onClick={onCatch}
										>
											Try to Catch!
										</Button>
									</div>
								</div>
							) : (
								''
							)}
							<div className="absolute right-2 bottom-2 border-2 bg-white/90 z-10">
								<div className="gap-1 w-30 h-30 max-sm:w-20 max-sm:h-20  grid grid-cols-3 grid-rows-3 place-items-center">
									{[
										'rotate-45',
										'rotate-90',
										'rotate-[135deg]',
										'',
										'',
										'rotate-180',
										'-rotate-45',
										'-rotate-90',
										'-rotate-[135deg]',
									].map((rotation, index) => {
										if (index == 4) {
											return (
												<span>
													{isLoading ? (
														<ImSpinner2
															color={css.redColor}
															className="animate-spin inline w-7 h-7 max-sm:w-4 max-sm:h-4"
														/>
													) : (
														<img
															src="https://i.dstatic.com/images/sprites/nuser263.png"
															alt="You"
															className="inline w-7 h-auto max-sm:w-4 max-sm:h-auto"
														/>
													)}
												</span>
											);
										} else
											return (
												<Arrow
													rotation={rotation}
													hideIcon={index == 4}
													id={index}
													disable={!availablePos?.[directions[index]]}
													onMove={onMove}
												/>
											);
									})}
								</div>
							</div>
							{/* <div className="w-full h-full grid grid-cols-3 grid-rows-3 place-items-center">
								{[
									'rotate-45',
									'rotate-90',
									'rotate-[135deg]',
									'',
									'',
									'rotate-180',
									'-rotate-45',
									'-rotate-90',
									'-rotate-[135deg]',
								].map((rotation, index) => {
									return <Arrow rotation={rotation} hideIcon={index == 4} />;
								})}
							</div> */}
							<span
								id="dirsprite1"
								className="w-8 h-8 max-sm:hidden md:max-2xl:block 2xl:block"
								style={{
									overflow: 'hidden',
									position: 'relative',
									top: `${parseInt(position.y) - 32}px`,
									left: `${parseInt(position.x) - 24}px`,
									zIndex: 6,
								}}
							>
								<img
									className={`${pokemon ? 'hidden' : ''}`}
									src="https://i.dstatic.com/images/sprites/nuser263.png"
									alt="You"
								/>
							</span>

							<span
								id="dirsprite2"
								className="sm:max-2xl:hidden 2xl:hidden max-sm:block w-6 h-6"
								style={{
									overflow: 'hidden',
									position: 'relative',
									top: `${parseInt(position.y) * 0.75 - 24}px`,
									left: `${parseInt(position.x) * 0.75 - 18}px`,
									zIndex: 6,
								}}
							>
								<img
									className={`${pokemon ? 'hidden' : ''}`}
									src="https://i.dstatic.com/images/sprites/nuser263.png"
									alt="You"
								/>
							</span>
						</div>
						<div className=" w-32 place-items-start bg-blue-100 bg-opacity-50 flex flex-col justify-end items-center hidden">
							<div className="w-full h-full">
								{pokemon ? (
									<div className="h-full flex flex-col justify-center max-sm:justify-end items-center text-center ">
										<img
											className="w-15 h-auto "
											src={`/images/front/${
												pokemonKind[pokemon.kind]
											}/${pokemon.name.toLowerCase()}.png`}
										/>
										<p className="text-xs font-semibold">
											Wild{' '}
											{`${
												pokemonKind[pokemon.kind] == 'normal'
													? ''
													: firstCapitalize(pokemonKind[pokemon.kind])
											} ${firstCapitalize(pokemon.name)} appeared!`}
										</p>
										<p className="text-sm  mb-1">Level : {pokemon.level}</p>
										<button
											className="bg-red-600 rounded-md text-sm text-blue-100 px-2"
											onClick={onCatch}
										>
											Try to Catch!
										</button>
									</div>
								) : (
									''
								)}
							</div>
							<div id="dirparent" style={{ padding: 0 }} className=" p-0 m-0">
								<div id="directions">
									<div className="row">
										<div
											title="Move North-West"
											onClick={() => {
												onMove(DIRECTIONS.NORTH_EAST);
											}}
											id="move_nw"
											data-dir="nw"
										>
											<div
												id="dr-nw"
												className={
													availablePos?.[DIRECTIONS.NORTH_EAST]
														? 'm-move'
														: 'm-disable'
												}
												title="Move North-west"
											></div>
										</div>
										<div
											title="Move North"
											onClick={() => {
												onMove(DIRECTIONS.NORTH);
											}}
											id="move_n"
											data-dir="n"
										>
											<div
												id="dr-n"
												className={
													availablePos?.[DIRECTIONS.NORTH]
														? 'm-move'
														: 'm-disable'
												}
												title="Move North"
											></div>
										</div>
										<div
											title="Move North-East"
											onClick={() => {
												onMove(DIRECTIONS.NORTH_WEST);
											}}
											id="move_ne"
											data-dir="ne"
										>
											<div
												id="dr-ne"
												className={
													availablePos?.[DIRECTIONS.NORTH_WEST]
														? 'm-move'
														: 'm-disable'
												}
												title="Move North-east"
											></div>
										</div>
									</div>
									<div className="row">
										<div
											title="Move West"
											onClick={() => {
												onMove(DIRECTIONS.EAST);
											}}
											id="move_w"
											data-dir="w"
										>
											<div
												id="dr-w"
												title="Move West"
												className={
													availablePos?.[DIRECTIONS.EAST]
														? 'm-move'
														: 'm-disable'
												}
											></div>
										</div>
										<span id="dirsprite">
											<img
												src="https://i.dstatic.com/images/sprites/nuser263.png"
												alt="You"
											/>
										</span>
										<div
											title="Move East"
											onClick={() => {
												onMove(DIRECTIONS.WEST);
											}}
											id="move_e"
											data-dir="e"
										>
											<div
												id="dr-e"
												className={
													availablePos?.[DIRECTIONS.WEST]
														? 'm-move'
														: 'm-disable'
												}
											></div>
										</div>
									</div>
									<div className="row">
										<div
											title="Move South-West"
											onClick={() => {
												onMove(DIRECTIONS.SOUTH_EAST);
											}}
											id="move_sw"
											data-dir="sw"
										>
											<div
												id="dr-sw"
												className={
													availablePos?.[DIRECTIONS.SOUTH_EAST]
														? 'm-move'
														: 'm-disable'
												}
												title="Move South-west"
											></div>
										</div>
										<div
											title="Move South"
											onClick={() => {
												onMove(DIRECTIONS.SOUTH);
											}}
											id="move_s"
											data-dir="s"
										>
											<div
												id="dr-s"
												className={
													availablePos?.[DIRECTIONS.SOUTH]
														? 'm-move'
														: 'm-disable'
												}
											></div>
										</div>
										<div
											title="Move South-East"
											onClick={() => {
												onMove(DIRECTIONS.SOUTH_WEST);
											}}
											id="move_se"
											data-dir="se"
										>
											<div
												id="dr-se"
												className={
													availablePos?.[DIRECTIONS.SOUTH_WEST]
														? 'm-move'
														: 'm-disable'
												}
											></div>
										</div>
									</div>
								</div>
								{/* <div id="d-keys" className="d-enable" style={{height: "20px"}}><div id="key-nav" title="" className="hastt" data-original-title="By enabling this, you can use the arrow keys or W,A,S,D keys to move around the map."><input style="top:3px;position:relative;" type="checkbox" name="enablekeys" checked="checked" id="keyen" wfd-id="id0">  <label style={{fontSize:"10px"}} htmlFor="keyen">Enable Keyboard Navigation?</label><br></div></div> */}
							</div>
						</div>
					</div>
					<div className="w-[496px]  max-sm:w-[256px] flex gap-3 items-center justify-center col-span-3 place-self-center m-4 text-blue-100">
						<div className="flex justify-start items-center flex-wrap  gap-4">
							<p className={css.LOGIN.pokeballTextClass}>
								Poke ball x <span> {profile?.items?.nball}</span>
							</p>
							<p className={css.LOGIN.pokeballTextClass}>
								Utlra ball x <span> {profile?.items?.uball}</span>
							</p>
							<p className={css.LOGIN.pokeballTextClass}>
								Super ball x <span> {profile?.items?.gball}</span>
							</p>
							<p className={css.LOGIN.pokeballTextClass}>
								Master ball x <span> {profile?.items?.mball}</span>
							</p>
						</div>
						<Button
							size="small"
							className="!bg-[#CE5A67] !text-[#FFF5E0] !px-2"
						>
							Go to mart?
						</Button>
					</div>
				</div>
			)}
		</div>
	);
}
