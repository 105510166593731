export default [
	'BULBASAUR',
	'IVYSAUR',
	'VENUSAUR',
	'CHARMANDER',
	'CHARMELEON',
	'CHARIZARD',
	'SQUIRTLE',
	'WARTORTLE',
	'BLASTOISE',
	'CATERPIE',
	'METAPOD',
	'BUTTERFREE',
	'WEEDLE',
	'KAKUNA',
	'BEEDRILL',
	'PIDGEY',
	'PIDGEOTTO',
	'PIDGEOT',
	'RATTATA',
	'RATICATE',
	'SPEAROW',
	'FEAROW',
	'EKANS',
	'ARBOK',
	'PIKACHU',
	'RAICHU',
	'SANDSHREW',
	'SANDSLASH',
	'NIDORINA',
	'NIDOQUEEN',
	'NIDORINO',
	'NIDOKING',
	'CLEFAIRY',
	'CLEFABLE',
	'VULPIX',
	'NINETALES',
	'JIGGLYPUFF',
	'WIGGLYTUFF',
	'ZUBAT',
	'GOLBAT',
	'ODDISH',
	'GLOOM',
	'VILEPLUME',
	'PARAS',
	'PARASECT',
	'VENONAT',
	'VENOMOTH',
	'DIGLETT',
	'DUGTRIO',
	'MEOWTH',
	'PERSIAN',
	'PSYDUCK',
	'GOLDUCK',
	'MANKEY',
	'PRIMEAPE',
	'GROWLITHE',
	'ARCANINE',
	'POLIWAG',
	'POLIWHIRL',
	'POLIWRATH',
	'ABRA',
	'KADABRA',
	'ALAKAZAM',
	'MACHOP',
	'MACHOKE',
	'MACHAMP',
	'BELLSPROUT',
	'WEEPINBELL',
	'VICTREEBEL',
	'TENTACOOL',
	'TENTACRUEL',
	'GEODUDE',
	'GRAVELER',
	'GOLEM',
	'PONYTA',
	'RAPIDASH',
	'SLOWPOKE',
	'SLOWBRO',
	'MAGNEMITE',
	'MAGNETON',
	"FARFETCH'D",
	'DODUO',
	'DODRIO',
	'SEEL',
	'DEWGONG',
	'GRIMER',
	'MUK',
	'SHELLDER',
	'CLOYSTER',
	'GASTLY',
	'HAUNTER',
	'GENGAR',
	'ONIX',
	'DROWZEE',
	'HYPNO',
	'KRABBY',
	'KINGLER',
	'VOLTORB',
	'ELECTRODE',
	'EXEGGCUTE',
	'EXEGGUTOR',
	'CUBONE',
	'MAROWAK',
	'HITMONLEE',
	'HITMONCHAN',
	'LICKITUNG',
	'KOFFING',
	'WEEZING',
	'RHYHORN',
	'RHYDON',
	'CHANSEY',
	'TANGELA',
	'KANGASKHAN',
	'HORSEA',
	'SEADRA',
	'GOLDEEN',
	'SEAKING',
	'STARYU',
	'STARMIE',
	'MR. MIME',
	'SCYTHER',
	'JYNX',
	'ELECTABUZZ',
	'MAGMAR',
	'PINSIR',
	'TAUROS',
	'MAGIKARP',
	'GYARADOS',
	'LAPRAS',
	'DITTO',
	'EEVEE',
	'VAPOREON',
	'JOLTEON',
	'FLAREON',
	'PORYGON',
	'OMANYTE',
	'OMASTAR',
	'KABUTO',
	'KABUTOPS',
	'AERODACTYL',
	'SNORLAX',
	'ARTICUNO',
	'ZAPDOS',
	'MOLTRES',
	'DRATINI',
	'DRAGONAIR',
	'DRAGONITE',
	'MEWTWO',
	'MEW',
	'CHIKORITA',
	'BAYLEEF',
	'MEGANIUM',
	'CYNDAQUIL',
	'QUILAVA',
	'TYPHLOSION',
	'TOTODILE',
	'CROCONAW',
	'FERALIGATR',
	'SENTRET',
	'FURRET',
	'HOOTHOOT',
	'NOCTOWL',
	'LEDYBA',
	'LEDIAN',
	'SPINARAK',
	'ARIADOS',
	'CROBAT',
	'CHINCHOU',
	'LANTURN',
	'PICHU',
	'CLEFFA',
	'IGGLYBUFF',
	'TOGEPI',
	'TOGETIC',
	'NATU',
	'XATU',
	'MAREEP',
	'FLAAFFY',
	'AMPHAROS',
	'BELLOSSOM',
	'MARILL',
	'AZUMARILL',
	'SUDOWOODO',
	'POLITOED',
	'HOPPIP',
	'SKIPLOOM',
	'JUMPLUFF',
	'AIPOM',
	'SUNKERN',
	'SUNFLORA',
	'YANMA',
	'WOOPER',
	'QUAGSIRE',
	'ESPEON',
	'UMBREON',
	'MURKROW',
	'SLOWKING',
	'MISDREAVUS',
	'UNOWN',
	'WOBBUFFET',
	'GIRAFARIG',
	'PINECO',
	'FORRETRESS',
	'DUNSPARCE',
	'GLIGAR',
	'STEELIX',
	'SNUBBULL',
	'GRANBULL',
	'QWILFISH',
	'SCIZOR',
	'SHUCKLE',
	'HERACROSS',
	'SNEASEL',
	'TEDDIURSA',
	'URSARING',
	'SLUGMA',
	'MAGCARGO',
	'SWINUB',
	'PILOSWINE',
	'CORSOLA',
	'REMORAID',
	'OCTILLERY',
	'DELIBIRD',
	'MANTINE',
	'SKARMORY',
	'HOUNDOUR',
	'HOUNDOOM',
	'KINGDRA',
	'PHANPY',
	'DONPHAN',
	'PORYGON2',
	'STANTLER',
	'SMEARGLE',
	'TYROGUE',
	'HITMONTOP',
	'SMOOCHUM',
	'ELEKID',
	'MAGBY',
	'MILTANK',
	'BLISSEY',
	'RAIKOU',
	'ENTEI',
	'SUICUNE',
	'LARVITAR',
	'PUPITAR',
	'TYRANITAR',
	'LUGIA',
	'HO-OH',
	'CELEBI',
	'TREECKO',
	'GROVYLE',
	'SCEPTILE',
	'TORCHIC',
	'COMBUSKEN',
	'BLAZIKEN',
	'MUDKIP',
	'MARSHTOMP',
	'SWAMPERT',
	'POOCHYENA',
	'MIGHTYENA',
	'ZIGZAGOON',
	'LINOONE',
	'WURMPLE',
	'SILCOON',
	'BEAUTIFLY',
	'CASCOON',
	'DUSTOX',
	'LOTAD',
	'LOMBRE',
	'LUDICOLO',
	'SEEDOT',
	'NUZLEAF',
	'SHIFTRY',
	'TAILLOW',
	'SWELLOW',
	'WINGULL',
	'PELIPPER',
	'RALTS',
	'KIRLIA',
	'GARDEVOIR',
	'SURSKIT',
	'MASQUERAIN',
	'SHROOMISH',
	'BRELOOM',
	'SLAKOTH',
	'VIGOROTH',
	'SLAKING',
	'NINCADA',
	'NINJASK',
	'SHEDINJA',
	'WHISMUR',
	'LOUDRED',
	'EXPLOUD',
	'MAKUHITA',
	'HARIYAMA',
	'AZURILL',
	'NOSEPASS',
	'SKITTY',
	'DELCATTY',
	'SABLEYE',
	'MAWILE',
	'ARON',
	'LAIRON',
	'AGGRON',
	'MEDITITE',
	'MEDICHAM',
	'ELECTRIKE',
	'MANECTRIC',
	'PLUSLE',
	'MINUN',
	'VOLBEAT',
	'ILLUMISE',
	'ROSELIA',
	'GULPIN',
	'SWALOT',
	'CARVANHA',
	'SHARPEDO',
	'WAILMER',
	'WAILORD',
	'NUMEL',
	'CAMERUPT',
	'TORKOAL',
	'SPOINK',
	'GRUMPIG',
	'SPINDA',
	'TRAPINCH',
	'VIBRAVA',
	'FLYGON',
	'CACNEA',
	'CACTURNE',
	'SWABLU',
	'ALTARIA',
	'ZANGOOSE',
	'SEVIPER',
	'LUNATONE',
	'SOLROCK',
	'BARBOACH',
	'WHISCASH',
	'CORPHISH',
	'CRAWDAUNT',
	'BALTOY',
	'CLAYDOL',
	'LILEEP',
	'CRADILY',
	'ANORITH',
	'ARMALDO',
	'FEEBAS',
	'MILOTIC',
	'CASTFORM',
	'KECLEON',
	'SHUPPET',
	'BANETTE',
	'DUSKULL',
	'DUSCLOPS',
	'TROPIUS',
	'CHIMECHO',
	'ABSOL',
	'WYNAUT',
	'SNORUNT',
	'GLALIE',
	'SPHEAL',
	'SEALEO',
	'WALREIN',
	'CLAMPERL',
	'HUNTAIL',
	'GOREBYSS',
	'RELICANTH',
	'LUVDISC',
	'BAGON',
	'SHELGON',
	'SALAMENCE',
	'BELDUM',
	'METANG',
	'METAGROSS',
	'REGIROCK',
	'REGICE',
	'REGISTEEL',
	'LATIAS',
	'LATIOS',
	'KYOGRE',
	'GROUDON',
	'RAYQUAZA',
	'JIRACHI',
	'TURTWIG',
	'GROTLE',
	'TORTERRA',
	'CHIMCHAR',
	'MONFERNO',
	'INFERNAPE',
	'PIPLUP',
	'PRINPLUP',
	'EMPOLEON',
	'STARLY',
	'STARAVIA',
	'STARAPTOR',
	'BIDOOF',
	'BIBAREL',
	'KRICKETOT',
	'KRICKETUNE',
	'SHINX',
	'LUXIO',
	'LUXRAY',
	'BUDEW',
	'ROSERADE',
	'CRANIDOS',
	'RAMPARDOS',
	'SHIELDON',
	'BASTIODON',
	'MOTHIM',
	'COMBEE',
	'VESPIQUEN',
	'PACHIRISU',
	'BUIZEL',
	'FLOATZEL',
	'CHERUBI',
	'CHERRIM',
	'SHELLOS',
	'GASTRODON',
	'AMBIPOM',
	'DRIFLOON',
	'DRIFBLIM',
	'BUNEARY',
	'LOPUNNY',
	'MISMAGIUS',
	'HONCHKROW',
	'GLAMEOW',
	'PURUGLY',
	'CHINGLING',
	'STUNKY',
	'SKUNTANK',
	'BRONZOR',
	'BRONZONG',
	'BONSLY',
	'MIME JR.',
	'HAPPINY',
	'CHATOT',
	'SPIRITOMB',
	'GIBLE',
	'GABITE',
	'GARCHOMP',
	'MUNCHLAX',
	'RIOLU',
	'LUCARIO',
	'HIPPOPOTAS',
	'HIPPOWDON',
	'SKORUPI',
	'DRAPION',
	'CROAGUNK',
	'TOXICROAK',
	'CARNIVINE',
	'FINNEON',
	'LUMINEON',
	'MANTYKE',
	'SNOVER',
	'ABOMASNOW',
	'WEAVILE',
	'MAGNEZONE',
	'LICKILICKY',
	'RHYPERIOR',
	'TANGROWTH',
	'ELECTIVIRE',
	'MAGMORTAR',
	'TOGEKISS',
	'YANMEGA',
	'LEAFEON',
	'GLACEON',
	'GLISCOR',
	'MAMOSWINE',
	'PORYGON-Z',
	'GALLADE',
	'PROBOPASS',
	'DUSKNOIR',
	'FROSLASS',
	'ROTOM',
	'UXIE',
	'MESPRIT',
	'AZELF',
	'DIALGA',
	'PALKIA',
	'HEATRAN',
	'REGIGIGAS',
	'CRESSELIA',
	'PHIONE',
	'MANAPHY',
	'DARKRAI',
	'ARCEUS',
	'VICTINI',
	'SNIVY',
	'SERVINE',
	'SERPERIOR',
	'TEPIG',
	'PIGNITE',
	'EMBOAR',
	'OSHAWOTT',
	'DEWOTT',
	'SAMUROTT',
	'PATRAT',
	'WATCHOG',
	'LILLIPUP',
	'HERDIER',
	'STOUTLAND',
	'PURRLOIN',
	'LIEPARD',
	'PANSAGE',
	'SIMISAGE',
	'PANSEAR',
	'SIMISEAR',
	'PANPOUR',
	'SIMIPOUR',
	'MUNNA',
	'MUSHARNA',
	'PIDOVE',
	'TRANQUILL',
	'UNFEZANT',
	'BLITZLE',
	'ZEBSTRIKA',
	'ROGGENROLA',
	'BOLDORE',
	'GIGALITH',
	'WOOBAT',
	'SWOOBAT',
	'DRILBUR',
	'EXCADRILL',
	'AUDINO',
	'TIMBURR',
	'GURDURR',
	'CONKELDURR',
	'TYMPOLE',
	'PALPITOAD',
	'SEISMITOAD',
	'THROH',
	'SAWK',
	'SEWADDLE',
	'SWADLOON',
	'LEAVANNY',
	'VENIPEDE',
	'WHIRLIPEDE',
	'SCOLIPEDE',
	'COTTONEE',
	'WHIMSICOTT',
	'PETILIL',
	'LILLIGANT',
	'SANDILE',
	'KROKOROK',
	'KROOKODILE',
	'DARUMAKA',
	'MARACTUS',
	'DWEBBLE',
	'CRUSTLE',
	'SCRAGGY',
	'SCRAFTY',
	'SIGILYPH',
	'YAMASK',
	'COFAGRIGUS',
	'TIRTOUGA',
	'CARRACOSTA',
	'ARCHEN',
	'ARCHEOPS',
	'TRUBBISH',
	'GARBODOR',
	'ZORUA',
	'ZOROARK',
	'MINCCINO',
	'CINCCINO',
	'GOTHITA',
	'GOTHORITA',
	'GOTHITELLE',
	'SOLOSIS',
	'DUOSION',
	'REUNICLUS',
	'DUCKLETT',
	'SWANNA',
	'VANILLITE',
	'VANILLISH',
	'VANILLUXE',
	'DEERLING',
	'SAWSBUCK',
	'EMOLGA',
	'KARRABLAST',
	'ESCAVALIER',
	'FOONGUS',
	'AMOONGUSS',
	'FRILLISH',
	'JELLICENT',
	'ALOMOMOLA',
	'JOLTIK',
	'GALVANTULA',
	'FERROSEED',
	'FERROTHORN',
	'KLINK',
	'KLANG',
	'KLINKLANG',
	'TYNAMO',
	'EELEKTRIK',
	'EELEKTROSS',
	'ELGYEM',
	'BEHEEYEM',
	'LITWICK',
	'LAMPENT',
	'CHANDELURE',
	'AXEW',
	'FRAXURE',
	'HAXORUS',
	'CUBCHOO',
	'BEARTIC',
	'CRYOGONAL',
	'SHELMET',
	'ACCELGOR',
	'STUNFISK',
	'MIENFOO',
	'MIENSHAO',
	'DRUDDIGON',
	'GOLETT',
	'GOLURK',
	'PAWNIARD',
	'BISHARP',
	'BOUFFALANT',
	'RUFFLET',
	'BRAVIARY',
	'VULLABY',
	'MANDIBUZZ',
	'HEATMOR',
	'DURANT',
	'DEINO',
	'ZWEILOUS',
	'HYDREIGON',
	'LARVESTA',
	'VOLCARONA',
	'COBALION',
	'TERRAKION',
	'VIRIZION',
	'RESHIRAM',
	'ZEKROM',
	'KYUREM',
	'GENESECT',
	'CHESPIN',
	'QUILLADIN',
	'CHESNAUGHT',
	'FENNEKIN',
	'BRAIXEN',
	'DELPHOX',
	'FROAKIE',
	'FROGADIER',
	'GRENINJA',
	'BUNNELBY',
	'DIGGERSBY',
	'FLETCHLING',
	'FLETCHINDER',
	'TALONFLAME',
	'SCATTERBUG',
	'SPEWPA',
	'VIVILLON',
	'LITLEO',
	'PYROAR',
	'FLOETTE',
	'FLORGES',
	'SKIDDO',
	'GOGOAT',
	'PANCHAM',
	'PANGORO',
	'FURFROU',
	'ESPURR',
	'HONEDGE',
	'DOUBLADE',
	'SPRITZEE',
	'AROMATISSE',
	'SWIRLIX',
	'SLURPUFF',
	'INKAY',
	'MALAMAR',
	'BINACLE',
	'BARBARACLE',
	'SKRELP',
	'DRAGALGE',
	'CLAUNCHER',
	'CLAWITZER',
	'HELIOPTILE',
	'HELIOLISK',
	'TYRUNT',
	'TYRANTRUM',
	'AMAURA',
	'AURORUS',
	'SYLVEON',
	'HAWLUCHA',
	'DEDENNE',
	'CARBINK',
	'GOOMY',
	'SLIGGOO',
	'GOODRA',
	'KLEFKI',
	'PHANTUMP',
	'TREVENANT',
	'BERGMITE',
	'AVALUGG',
	'NOIBAT',
	'NOIVERN',
	'XERNEAS',
	'YVELTAL',
	'DIANCIE',
	'VOLCANION',
	'ROWLET',
	'DARTRIX',
	'DECIDUEYE',
	'LITTEN',
	'TORRACAT',
	'INCINEROAR',
	'POPPLIO',
	'BRIONNE',
	'PRIMARINA',
	'PIKIPEK',
	'TRUMBEAK',
	'TOUCANNON',
	'YUNGOOS',
	'GUMSHOOS',
	'GRUBBIN',
	'CHARJABUG',
	'VIKAVOLT',
	'CRABRAWLER',
	'CRABOMINABLE',
	'CUTIEFLY',
	'RIBOMBEE',
	'ROCKRUFF',
	'MAREANIE',
	'TOXAPEX',
	'MUDBRAY',
	'MUDSDALE',
	'DEWPIDER',
	'ARAQUANID',
	'FOMANTIS',
	'LURANTIS',
	'MORELULL',
	'SHIINOTIC',
	'SALANDIT',
	'SALAZZLE',
	'STUFFUL',
	'BEWEAR',
	'BOUNSWEET',
	'STEENEE',
	'TSAREENA',
	'COMFEY',
	'ORANGURU',
	'PASSIMIAN',
	'WIMPOD',
	'GOLISOPOD',
	'SANDYGAST',
	'PALOSSAND',
	'PYUKUMUKU',
	'TYPE: NULL',
	'SILVALLY',
	'KOMALA',
	'TURTONATOR',
	'TOGEDEMARU',
	'MIMIKYU',
	'BRUXISH',
	'DRAMPA',
	'DHELMISE',
	'JANGMO-O',
	'HAKAMO-O',
	'KOMMO-O',
	'TAPU KOKO',
	'TAPU LELE',
	'TAPU BULU',
	'TAPU FINI',
	'COSMOG',
	'COSMOEM',
	'SOLGALEO',
	'LUNALA',
	'NIHILEGO',
	'BUZZWOLE',
	'PHEROMOSA',
	'XURKITREE',
	'CELESTEELA',
	'KARTANA',
	'GUZZLORD',
	'NECROZMA',
	'MAGEARNA',
	'MARSHADOW',
	'POIPOLE',
	'NAGANADEL',
	'STAKATAKA',
	'BLACEPHALON',
	'ZERAORA',
	'MELTAN',
	'MELMETAL',
	'GROOKEY',
	'THWACKEY',
	'RILLABOOM',
	'SCORBUNNY',
	'RABOOT',
	'CINDERACE',
	'SOBBLE',
	'DRIZZILE',
	'INTELEON',
	'SKWOVET',
	'GREEDENT',
	'ROOKIDEE',
	'CORVISQUIRE',
	'CORVIKNIGHT',
	'BLIPBUG',
	'DOTTLER',
	'ORBEETLE',
	'NICKIT',
	'THIEVUL',
	'GOSSIFLEUR',
	'ELDEGOSS',
	'WOOLOO',
	'DUBWOOL',
	'CHEWTLE',
	'DREDNAW',
	'YAMPER',
	'BOLTUND',
	'ROLYCOLY',
	'CARKOL',
	'COALOSSAL',
	'APPLIN',
	'FLAPPLE',
	'APPLETUN',
	'SILICOBRA',
	'SANDACONDA',
	'CRAMORANT',
	'ARROKUDA',
	'BARRASKEWDA',
	'TOXEL',
	'SIZZLIPEDE',
	'CENTISKORCH',
	'CLOBBOPUS',
	'GRAPPLOCT',
	'SINISTEA',
	'POLTEAGEIST',
	'HATENNA',
	'HATTREM',
	'HATTERENE',
	'IMPIDIMP',
	'MORGREM',
	'GRIMMSNARL',
	'OBSTAGOON',
	'PERRSERKER',
	'CURSOLA',
	"SIRFETCH'D",
	'MR. RIME',
	'RUNERIGUS',
	'MILCERY',
	'ALCREMIE',
	'FALINKS',
	'PINCURCHIN',
	'SNOM',
	'FROSMOTH',
	'STONJOURNER',
	'CUFANT',
	'COPPERAJAH',
	'DRACOZOLT',
	'ARCTOZOLT',
	'DRACOVISH',
	'ARCTOVISH',
	'DURALUDON',
	'DREEPY',
	'DRAKLOAK',
	'DRAGAPULT',
	'ETERNATUS',
];
