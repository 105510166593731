import { TbPokeball } from 'react-icons/tb';
import Navigation from './Navigation';
const textColor = '#CE5A67';
export function Header() {
	return (
		<div className="relative z-10">
			<div className="absolute  max-md:left-1/2 max-md:-translate-x-1/2">
				<div className="flex  items-center ml-2">
					<TbPokeball color={textColor} size={30} />
					<h1
						className={`sm:max-md:text-lg font-extrabold md:max-xl:text-sm text-[${textColor}]`}
					>
						PokeLeagueRPG
					</h1>
				</div>
			</div>
			<Navigation />
		</div>
	);
}
