import { useNavigate } from 'react-router-dom';
import Request from '../utils/Request';
import { setItem } from '../utils/storage.utils';
import { useEffect } from 'react';
import { Header } from '../componetns/Header';
import { css } from '../css_constants';

export function MapSelection(props) {
	const navigate = useNavigate();
	useEffect(() => {
		if (Request.isTokenValid()) {
		} else {
			navigate('/Login', { replace: true });
		}
	}, []);

	return (
		<div>
			<Header />
			<div className="flex justify-center items-center">
				<h1
					className={`place-self-center p-2 m-1 my-5 ${css.LOGIN.titlebgColor} rounded-md`}
				>
					Please select a map
				</h1>
			</div>
			<div className="flex max-sm:flex-col gap-3 justify-center items-center">
				<img
					onClick={() => {
						navigate('/Map', { state: { map: 'green_land' }, replace: true });
					}}
					className="size-72 cursor-pointer hover:shadow-md hover:shadow-green-50"
					src={`/green_land.png`}
					// src={`/${pokemon.name.toLowerCase()}.png`}
				/>
				<img
					onClick={() => {
						navigate('/Map', { state: { map: 'green_land2' }, replace: true });
					}}
					className="size-72 cursor-pointer hover:shadow-md hover:shadow-green-50"
					src={`/green_land2.png`}
					// src={`/${pokemon.name.toLowerCase()}.png`}
				/>
			</div>
		</div>
	);
}
