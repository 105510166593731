import axios from 'axios';
import { pokemonKind } from '../constants';
import { FaS } from 'react-icons/fa6';
import { setItem } from './storage.utils';
import { Logout } from '@mui/icons-material';

const URL = 'http://localhost:3001';
class Request {
	async userProfile() {
		try {
			const res = await axios.get(url(PROFILE_URL));
			console.log(res.data);
			if (!res.data) {
				localStorage.setItem('token', '');
				throw 'Please login';
			}
			setItem('profile', res.data.profile);
			return res;
		} catch (error) {
			console.log('This is error', error);
			setItem('token', '');
			throw error;
		}
	}

	async addPokemon(name: string) {
		try {
			return axios.post(url(POKEMON), {
				pokemons: [name.toLowerCase()],
			});
		} catch (error) {}
	}

	async mapRequest(map) {
		try {
			return axios.post(url(MAP_REQUEST), {
				mapName: map,
			});
		} catch (error) {}
	}

	async mapMove(dir) {
		try {
			return axios.post(url(MAP_MOVE), {
				dir: dir,
			});
		} catch (error) {}
	}

	async startWildBattle() {
		try {
			return axios.post(url(START_WILD_BATTLE));
		} catch (error) {}
	}

	async selectBattlePokemon(name) {
		try {
			return axios.post(url(SELECT_BATTLE_POKEMON), {
				selectedPokemon: name,
			});
		} catch (error) {}
	}

	async catch(name) {
		try {
			return axios.post(url(CATCH_POKEMON), {
				ballType: name,
			});
		} catch (error) {}
	}

	async selectAttack(attackId) {
		try {
			return axios.post(url(SELECT_ATTACK), {
				attackId: attackId,
			});
		} catch (error) {}
	}

	async login(username, password) {
		try {
			const res = await axios.post(url(LOGIN_URL), {
				username: username,
				password: password,
			});

			localStorage.setItem('token', res?.data?.access_token);
			return this.isTokenValid();
		} catch (error) {
			return false;
		}
	}

	async signup(username, password) {
		try {
			const res = await axios.post(url(SIGNUP_URL), {
				username: username,
				password: password,
			});

			localStorage.setItem('token', res?.data?.access_token);
			return { valid: this.isTokenValid(), data: res?.data };
		} catch (error) {
			return { valid: false, data: error?.response?.data };
		}
	}

	getTeam() {
		try {
			return axios.get(url(TEAM));
		} catch (error) {}
	}

	getPokemons(skip) {
		try {
			return axios.get(url(FIND_NEXT_POKEMONS) + '?skip=' + skip);
		} catch (error) {}
	}

	getPokemonsExcludeTeam(skip) {
		try {
			return axios.get(url(FIND_NEXT_POKEMONS_TEAM_EXCLUDE) + '?skip=' + skip);
		} catch (error) {}
	}

	updateTeam(pokemons) {
		try {
			return axios.post(url(UPDATE_POKEMONS), {
				pokemons,
			});
		} catch (error) {}
	}

	evolve(id) {
		try {
			return axios.post(url(EVOLVE), {
				id,
			});
		} catch (error) {}
	}

	replaceTeamPokemon(from, to) {
		try {
			return axios.post(url(REPLACE_TEAM_POKEMON), {
				from,
				to,
			});
		} catch (error) {}
	}

	startGymBattle(id) {
		try {
			return axios.post(url(START_GYM_BATTLE), {
				id,
			});
		} catch (error) {}
	}

	startUserBattle(name) {
		try {
			return axios.post(url(START_USER_BATTLE), {
				name,
			});
		} catch (error) {}
	}

	gymRegionDetails() {
		try {
			return axios.get(url(GYM_REGION_DETAILS));
		} catch (error) {}
	}

	battleContinue() {
		try {
			return axios.post(url(BATTLE_CONTINUE));
		} catch (error) {}
	}

	startTrainingBattle(type) {
		try {
			return axios.post(url(START_TRAINING_BATTLE), { type });
		} catch (error) {}
	}

	getPokeItems() {
		try {
			return axios.get(url(POKE_ITEMS));
		} catch (error) {}
	}

	getPokeMartDetails() {
		try {
			return axios.get(url(POKE_MART_DETAILS));
		} catch (error) {}
	}

	purchase(items) {
		try {
			return axios.post(url(PURCHASE), { items });
		} catch (error) {}
	}

	pokemonsInTrade(skip) {
		try {
			return axios.get(url(POKEMONS_IN_TRADE) + '?skip=' + skip);
		} catch (error) {}
	}

	pokemonsInOffer(skip) {
		try {
			return axios.get(url(POKEMONS_IN_OFFER) + '?skip=' + skip);
		} catch (error) {}
	}

	rejectPokemonOffer(offer, pokemonOnTrade) {
		try {
			return axios.post(url(REJECT_TRADE_OFFER), {
				offer: offer,
				pokemonOnTrade,
			});
		} catch (error) {}
	}

	acceptPokemonOffer(offer, pokemonOnTrade) {
		try {
			return axios.post(url(ACCEPT_TRADE_OFFER), {
				offer: offer,
				pokemonOnTrade,
			});
		} catch (error) {}
	}

	removeOfferFromTrade(pokemons) {
		try {
			return axios.post(url(REMOVE_OFFER_FROM_TRADE), {
				pokemons,
			});
		} catch (error) {}
	}

	moveToTrade(pokemons) {
		try {
			return axios.post(url(MOVE_TO_TRADE), {
				pokemons,
			});
		} catch (error) {}
	}

	removePokemonFromTrade(pokemons) {
		try {
			return axios.post(url(REMOVE_POKEMON_FROM_TRADE), {
				pokemons,
			});
		} catch (error) {}
	}

	searchPokemonInTrade(pokemon, skip = 0) {
		try {
			return axios.get(
				url(SEARCH_POKEMON_IN_TRADE) + '?pokemon=' + pokemon + '&skip=' + skip
			);
		} catch (error) {}
	}

	logout() {
		try {
			return axios.post(url(LOGOUT));
		} catch (error) {}
	}

	isTokenValid() {
		const token = localStorage.getItem('token');
		if (token) {
			return true;
		}
		return false;
	}
}
export const url = (path) => {
	// return `${URL}${path}`;
	return `${path}`;
};

export const LOGIN_URL = '/api/auth/login';
export const SIGNUP_URL = '/api/auth/signup';
export const LOGOUT = '/api/auth/logout';
export const PROFILE_URL = '/api/auth/profile';
export const POKEMON = '/api/game/pokemon';
export const MAP_REQUEST = '/api/game/mapRequest';
export const MAP_MOVE = '/api/game/mapMove';
export const START_WILD_BATTLE = '/api/game/startWildBattle';
export const SELECT_BATTLE_POKEMON = '/api/game/selectBattlePokemon';
export const SELECT_ATTACK = '/api/game/attack';
export const CATCH_POKEMON = '/api/game/catch';
export const TEAM = '/api/game/team';
export const UPDATE_POKEMONS = '/api/game/updatePokemons';
export const EVOLVE = '/api/game/evolve';
export const FIND_NEXT_POKEMONS = '/api/game/findNextPokemons';
export const REPLACE_TEAM_POKEMON = '/api/game/replaceTeamPokemon';
export const START_GYM_BATTLE = '/api/game/startGymBattle';
export const START_USER_BATTLE = '/api/game/startUserBattle';
export const START_TRAINING_BATTLE = '/api/game/startTrainingBattle';
export const GYM_REGION_DETAILS = '/api/game/gym/regionsData';
export const BATTLE_CONTINUE = '/api/game/battleContinue';
export const POKE_ITEMS = '/api/game/items';
export const POKE_MART_DETAILS = '/api/game/getPokemartDetails';
export const PURCHASE = '/api/game/purchase';
export const POKEMONS_IN_TRADE = '/api/game/pokemonsInTrade';
export const POKEMONS_IN_OFFER = '/api/game/pokemonsInOffer';
export const REJECT_TRADE_OFFER = '/api/game/rejectTradeOffer';
export const ACCEPT_TRADE_OFFER = '/api/game/acceptTradeOffer';
export const REMOVE_OFFER_FROM_TRADE = '/api/game/removeAOfferFromTrade';
export const MOVE_TO_TRADE = '/api/game/moveToTrade';
export const REMOVE_POKEMON_FROM_TRADE = '/api/game/removePokemonFromTrade';
export const SEARCH_POKEMON_IN_TRADE = '/api/game/searchPokemonInTrade';

export const FIND_NEXT_POKEMONS_TEAM_EXCLUDE =
	'/api/game/findNextPokemonsExcludeTeam';

export default new Request();
