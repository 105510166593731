import {
	Button,
	Menu,
	MenuButton,
	MenuItem,
	MenuItems,
	Transition,
} from '@headlessui/react';
import { Header } from '../componetns/Header';
import { pokemonKind } from '../constants';
import { firstCapitalize } from '../utils/utils';
import { getPokemonTypes } from '../utils/utils.component';
import { Attack } from '../componetns/Attack';
import moveDetails from '../utils/move_power';
import { useEffect, useState } from 'react';
import Request from '../utils/Request';
import { ChevronDownIcon } from '@heroicons/react/16/solid';
import { MagnifyingGlassIcon } from '@heroicons/react/16/solid';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ImSpinner2 } from 'react-icons/im';
import { setItem } from '../utils/storage.utils';
import { useNavigate } from 'react-router-dom';
import { DialogBox } from '../componetns/Dialog';
import { css } from '../css_constants';
import { Autocomplete, Button as ButtonUI, TextField } from '@mui/material';
import { EvolveDailog } from '../componetns/EvolveDailog';
import pokeNames from '../utils/pokeNames';

export default function AttackDrowpdown(props) {
	return (
		<div className="text-right">
			<Menu>
				<MenuButton className="my-[1px] ml-[0.5px] inline-flex items-center gap-2 rounded-sm bg-gray-800 py-1.5 px-3 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-700 data-[open]:bg-gray-700 data-[focus]:outline-1 data-[focus]:outline-white">
					<Attack
						className="hover:bg-white/50"
						index={1}
						name={props.attack}
						onlyIcon={true}
						flip={true}
						isOpponent={true}
						onSelect={props?.onSelect}
						// type={moveDetails[attack.toLowerCase()].type}
						type={moveDetails[props.attack].type}
						checked={props?.select == 1 ? true : false}
					/>
					<ChevronDownIcon className="size-4 fill-white/60" />
				</MenuButton>
				<Transition
					enter="transition ease-out duration-75"
					enterFrom="opacity-0 scale-95"
					enterTo="opacity-100 scale-100"
					leave="transition ease-in duration-100"
					leaveFrom="opacity-100 scale-100"
					leaveTo="opacity-0 scale-95"
				>
					<MenuItems
						anchor="bottom end"
						className="origin-top-right rounded-xl border border-white/5 bg-gray-800 p-1 text-sm/6 text-white [--anchor-gap:var(--spacing-1)] focus:outline-none "
					>
						{props?.unlockedMoves?.map((move, index) => {
							return (
								<MenuItem>
									<button
										onClick={() => {
											props.onAttackSelect(move, index + 1);
										}}
										className="group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10"
									>
										<Attack
											onlyIcon={true}
											index={1}
											flip={true}
											name={move}
											showAccuracy={true}
											isOpponent={true}
											onSelect={props?.onSelect}
											// type={moveDetails[attack.toLowerCase()].type}
											type={moveDetails[move].type}
											checked={props?.select == 1 ? true : false}
										/>

										<kbd className="ml-auto font-sans text-xs text-white/50 group-data-[focus]:inline">
											⌘
										</kbd>
									</button>
								</MenuItem>
							);
						})}
					</MenuItems>
				</Transition>
			</Menu>
		</div>
	);
}

function PokemonItem(props) {
	const [showLoading, setShowLoading] = useState(false);
	useEffect(() => {
		if (props?.isChanged && showLoading) {
			setShowLoading(false);
		}
	});
	return (
		<div className="w-[80%] flex flex-col justify-center items-center gap-2 ">
			<div
				className={`${css.bgLightColor}/90 w-[100%] py-2 rounded-md grid grid-cols-2 md:max-2xl:flex 2xl:flex items-center justify-evenly justify-items-center gap-2`}
			>
				<div className=" flex flex-col justify-center items-center">
					<img
						className="max-w-24 max-h-24 w-auto h-auto"
						src={`/images/front/${
							pokemonKind[props.pokemon?.kind]
						}/${props.pokemon?.name.toLowerCase()}.png`}
						// src="/abomasnow.png"
					/>
					<p className="font-semibold text-sm">
						{props?.pokemon?.user.username
							? firstCapitalize(props?.pokemon?.user.username) + "'s "
							: ''}
						{pokemonKind[props?.pokemon?.kind] == 'normal'
							? ''
							: firstCapitalize(pokemonKind[props?.pokemon?.kind])}{' '}
						{firstCapitalize(props?.pokemon?.name || 'Trevenant')}
					</p>
				</div>
				<div className="place-content-center">
					<div className="flex flex-col justify-center items-center">
						<p className="text-xs">Level : {props?.pokemon?.level}</p>
						<p className="text-xs">Experience : {props?.pokemon?.exp}</p>
					</div>
					<div className="flex justify-center text-xs font-bold">
						{getPokemonTypes(props?.pokemon?.type)}
					</div>
				</div>
				<div className="flex flex-col">
					<Attack
						onlyIcon={true}
						index={1}
						flip={true}
						name={props.pokemon?.attack1}
						isOpponent={true}
						onSelect={props?.onSelect}
						// type={moveDetails[attack.toLowerCase()].type}
						type={moveDetails[props.pokemon?.attack1].type}
						checked={props?.select == 1 ? true : false}
					/>
					<Attack
						onlyIcon={true}
						index={2}
						flip={true}
						name={props.pokemon?.attack2}
						isOpponent={true}
						onSelect={props?.onSelect}
						// type={moveDetails[attack.toLowerCase()].type}
						type={moveDetails[props.pokemon?.attack2].type}
						checked={props?.select == 1 ? true : false}
					/>
					<Attack
						onlyIcon={true}
						index={3}
						flip={true}
						name={props.pokemon.attack3}
						isOpponent={true}
						onSelect={props?.pokemon?.onSelect}
						// type={moveDetails[attack.toLowerCase()].type}
						type={moveDetails[props.pokemon?.attack3].type}
						checked={props?.select == 1 ? true : false}
					/>
					<Attack
						onlyIcon={true}
						index={4}
						flip={true}
						name={props.pokemon?.attack4}
						isOpponent={true}
						onSelect={props?.onSelect}
						// type={moveDetails[attack.toLowerCase()].type}
						type={moveDetails[props.pokemon?.attack4].type}
						checked={props?.select == 1 ? true : false}
					/>
				</div>
				{!showLoading ? (
					<div className="flex flex-col p-4 underline hover:cursor-pointer">
						<Button
							onClick={() => {
								props.clicks.onRemoveOfferAll(
									props?.myOffers?.map((poke) => {
										return poke.id;
									})
								);
							}}
						>
							Remove all the offers
						</Button>
						{props?.pokemon?.in_team ? (
							<Button
								onClick={() => {
									props?.replace(props.id);
								}}
							>
								Replace
							</Button>
						) : (
							''
						)}
						{props?.isChanged ? (
							showLoading ? (
								<ImSpinner2 className="animate-spin inline mr-1" />
							) : (
								<Button
									onClick={() => {
										setShowLoading(true);
										props?.save(props?.pokemon);
									}}
								>
									Save
								</Button>
							)
						) : (
							''
						)}
					</div>
				) : (
					''
				)}
			</div>
			<div className="flex justify-end items-end justify-self-end flex-wrap self-end">
				{props?.myOffers
					? props?.myOffers?.map((poke) => {
							return (
								<div
									className={` ${css.bgLightColor}/90 m-2 py-2 rounded-md flex flex-col w-auto basis-auto shrink-1 grow-0 items-center justify-center gap-2`}
								>
									<div
										className={`flex items-center justify-center  flex-wrap`}
									>
										<div className="flex flex-col p-2  ">
											<div className=" flex flex-col justify-center items-center">
												<img
													className="max-w-12 max-h-12 w-auto h-auto"
													src={`/images/front/${
														pokemonKind[poke.kind]
													}/${poke.name.toLowerCase()}.png`}
													// src="/abomasnow.png"
												/>
												<p className="font-semibold text-xs">
													{pokemonKind[poke.kind] == 'normal'
														? ''
														: firstCapitalize(pokemonKind[poke.kind])}{' '}
													{firstCapitalize(poke.name || 'Trevenant')}
												</p>
											</div>
											<div className="p-1 place-content-center">
												<div className="flex flex-col justify-center items-center">
													<p className="text-[10px]">Level : {poke.level}</p>
													<p className="text-[10px]">Experience : {poke.exp}</p>
												</div>
												<div className="flex justify-center text-xs font-bold">
													{getPokemonTypes(poke.type)}
												</div>
											</div>
										</div>
									</div>
									{!showLoading ? (
										<div className="flex p-4 hover:cursor-pointer">
											<ButtonUI
												onClick={() => {
													props.clicks.onRemoveAOffer([poke.id]);
												}}
												variant="contained"
												size="small"
												className={`mx-1 bg-red-500`}
											>
												Remove From offer?
											</ButtonUI>
										</div>
									) : (
										''
									)}
								</div>
							);
					  })
					: ''}
			</div>
		</div>
	);
}

export function Offers(props) {
	const [pokemons, setPokemons] = useState({} as any);
	const [pokemonsDetails, setPokemonsDetails] = useState({} as any);
	const [skip, setSkip] = useState(0);
	const [isChanged, setIsChanged] = useState(-1);
	const [inProgress, setInProgress] = useState(false);
	const [maxPokemon, setMaxPokemon] = useState(0);
	const [loading, setLoading] = useState(false);
	const [reload, setReload] = useState(0);
	const [searchPokemonName, setSearchPokemonName] = useState('');
	const navigate = useNavigate();

	const notifySaved = (text?) => toast.success(text || 'Saved');
	const notifySavedFailure = (text) => toast.error(text);

	useEffect(() => {
		if (skip === 0 || skip < maxPokemon) {
			setInProgress(true);
			Request.pokemonsInOffer(skip)
				.then((res) => {
					const offeredTo = {};
					const offeredToDetails = {};
					res.data.pokemons.map((details) => {
						if (!offeredTo[details.offeredTo.id]) {
							offeredTo[details.offeredTo.id] = [];
							offeredToDetails[details.offeredTo.id] = details.offeredTo;
						}
						offeredTo[details.offeredTo.id].push(details);
					});
					console.log(offeredTo, offeredToDetails);
					setPokemons(offeredTo);
					setPokemonsDetails(offeredToDetails);
					setMaxPokemon(res.data.count);
					if (res.data.count <= 0) {
						notifySavedFailure('You do not have an offers');
					}
				})
				.catch(() => {})
				.finally(() => {
					setLoading(false);
					setInProgress(false);
				});
		}
	}, [skip, reload]);

	function moveUp(index) {
		if (index - 1 >= 0) {
			let temp = pokemons[index - 1];
			pokemons[index - 1] = pokemons[index];
			pokemons[index] = temp;
			pokemons[index - 1].team_order = index - 1;
			pokemons[index].team_order = index;
			pokemons.sort((a, b) => {
				return a.team_order - b.team_order;
			});
			setPokemons([...pokemons]);
		}
	}

	function moveDown(index) {
		if (index + 1 < pokemons.length) {
			let temp = pokemons[index + 1];
			pokemons[index + 1] = pokemons[index];
			pokemons[index] = temp;
			pokemons[index + 1].team_order = index + 1;
			pokemons[index].team_order = index;
			pokemons.sort((a, b) => {
				return a.team_order - b.team_order;
			});
			setPokemons([...pokemons]);
		}
	}

	function onAttackSelect(index, attack, attackId) {
		if (pokemons[index]) {
			pokemons[index][`attack${attackId}`] = attack;
			setPokemons([...pokemons]);
			setIsChanged(index);
		}
	}

	function replace(index) {
		if (pokemons[index]) {
			setItem('changeTeam', pokemons[index]);
			navigate('/changeTeam', {
				replace: true,
			});
		}
	}

	function save(pokemon) {
		Request.updateTeam([pokemon])
			.then(() => {
				notifySaved();
			})
			.catch(() => {
				notifySavedFailure('Not able to save right now');
			})
			.finally(() => {
				setIsChanged(-1);
			});
	}

	async function onRemoveOffer(pokemons) {
		try {
			await Request.removeOfferFromTrade(pokemons);
			notifySaved('Removed the offer');
			setInProgress(true);
			setReload(reload + 1);
		} catch (error) {
			notifySavedFailure('Could not trade at this time');
			console.error(error);
		}
	}

	async function searchPokemon() {
		try {
			Request.searchPokemonInTrade(searchPokemonName.toLowerCase());
		} catch (error) {
			notifySavedFailure('Cannot search right now');
			console.log(error);
		}
	}

	return !inProgress ? (
		<div>
			<Header />
			<div className="flex flex-col justify-center items-center">
				<h1
					className={`col-span-3 place-self-center p-2 m-1 mt-5 ${css.LOGIN.titlebgColor} text-[#CE5A67] rounded-md`}
				>
					Pokemons You Offered
				</h1>
				<div className="flex justify-center items-center">
					<Autocomplete
						disablePortal
						options={pokeNames}
						value={searchPokemonName}
						onChange={(event, value) => {
							setSearchPokemonName(value);
						}}
						sx={{
							width: 300,
							color: '#CE5A67',
							'& .MuiAutocomplete-popper': {
								backgroundColor: css.lightColor,
							},
						}}
						className={`${css.bgLightColor} ${css.textRedColr}`}
						renderInput={(params) => (
							<TextField
								{...params}
								className={`${css.textRedColr}`}
								label="Search for a pokemon"
							/>
						)}
					/>
					<MagnifyingGlassIcon
						className="size-10 ml-4 fill-white/60 cursor-pointer"
						onClick={searchPokemon}
					/>
				</div>
				<div
					className={`grid grid-rows-${pokemons.length} gap-4 place-items-center`}
				>
					{(() => {
						const arr = [];
						for (const key in pokemons) {
							arr.push(
								<PokemonItem
									clicks={{
										onRemoveOfferAll: onRemoveOffer,
										onRemoveAOffer: onRemoveOffer,
									}}
									pokemon={pokemonsDetails[key]}
									myOffers={pokemons[key]}
									id={key}
									onAttackSelect={onAttackSelect}
									moveUp={moveUp}
									isChanged={false}
									save={save}
									moveDown={moveDown}
									replace={replace}
								/>
							);
						}
						return arr;
					})()}
				</div>
			</div>
			<div className="flex justify-center items-center">
				<ButtonUI
					variant="contained"
					size="small"
					disabled={skip <= 0 || loading}
					className={`${css.bgRedColor} m-2 ${
						skip <= 0 || loading ? 'grayscale' : ''
					}`}
					onClick={(event) => {
						event.preventDefault();
						if (skip >= 10) {
							setLoading(true);
							setSkip(skip - 10);
						}
					}}
				>
					PREV
				</ButtonUI>
				<ButtonUI
					variant="contained"
					size="small"
					disabled={skip + 10 >= maxPokemon || loading}
					className={`${css.bgRedColor} m-2 ${
						skip + 10 >= maxPokemon || loading ? 'grayscale' : ''
					}`}
					onClick={(event) => {
						event.preventDefault();
						console.log(skip);
						if (skip + 10 <= maxPokemon) {
							setLoading(true);
							setSkip(skip + 10);
						}
					}}
				>
					NEXT
				</ButtonUI>
			</div>

			<ToastContainer
				theme="colored"
				autoClose={2000}
				position="bottom-center"
			/>
		</div>
	) : (
		<div>
			<Header />
			<div className="h-[400px] flex flex-col justify-center items-center">
				<ImSpinner2
					size={30}
					color={css.lightColor}
					className="animate-spin inline mr-1"
				/>
				<h1 className={`text-lg m-4 ${css.textLightColor}`}>
					Fetching pokemons in trade
				</h1>
			</div>
			<ToastContainer
				theme="colored"
				autoClose={2000}
				position="bottom-center"
			/>
		</div>
	);
}
