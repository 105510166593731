import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
	createBrowserRouter,
	createRoutesFromElements,
	Route,
	RouterProvider,
} from 'react-router-dom';
import Login from './pages/Login';
import Signup from './pages/Singup';
import Home from './pages/Home';
import Map from './pages/Map';
import Battle from './pages/Battle';
import { Team } from './pages/Team';
import { Pokemons } from './pages/Pokemons';
import { ChangeTeam } from './pages/ChangeTeam';
import { Gym } from './pages/Gym';
import { UserSearch } from './pages/UserSearch';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { ExpGym } from './pages/ExpGym';
import { PokeMart } from './pages/PokeMart';
import { Trades } from './pages/Trades';
import { Offers } from './pages/Offers';
import { MapSelection } from './pages/MapSelection';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
	{
		path: '/',
		element: <Home />,
	},
	{
		path: '/login',
		element: <Login />,
	},
	{
		path: '/signup',
		element: <Login />,
	},
	{
		path: '/home',
		element: <Home />,
	},
	{
		path: '/map',
		element: <Map />,
	},
	{
		path: '/battle',
		element: <Battle />,
	},
	{
		path: '/gymbattle',
		element: <Gym />,
	},
	{
		path: '/team',
		element: <Team />,
	},
	{
		path: '/pokemons',
		element: <Pokemons />,
	},
	{
		path: '/changeTeam',
		element: <ChangeTeam />,
	},
	{
		path: '/userbattle',
		element: <UserSearch />,
	},
	{
		path: '/expbattle',
		element: <ExpGym />,
	},
	{
		path: '/pokemart',
		element: <PokeMart />,
	},
	{
		path: '/trades',
		element: <Trades />,
	},
	{
		path: '/offers',
		element: <Offers />,
	},
	{
		path: '/mapSelection',
		element: <MapSelection />,
	},
]);

const routers = createBrowserRouter(
	createRoutesFromElements(
		<Route path="/" element={<Home />}>
			<Route path="login" element={<Login />} />
			<Route path="signup" element={<Signup />} />
			<Route path="map" element={<Map />} />
			<Route path="battle" element={<Battle />} />
			<Route path="team" element={<Team />} />
			<Route path="pokemons" element={<Pokemons />} />
			<Route path="changeTeam" element={<ChangeTeam />} />
		</Route>
	)
);

root.render(
	<React.StrictMode>
		<RouterProvider router={router} />
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
